

<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-start mb-0">FAQs</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Frequently asked questions on sabenzi</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
                    <div class="mb-1 breadcrumb-right">
                        <base-card-drop-down>
                            <template #title>
                                Options
                            </template>
                            <template #default>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-check-circle" ></i>
                                    FAQs
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-circle" ></i>
                                    Go to tutorial section
                                </a>
                            </template>
                        </base-card-drop-down>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <!-- Knowledge base Jumbotron -->
                <section id="knowledge-base-search">
                    <div class="row">
                        <div class="col-12">
                            <base-card-plain textAlign="text-center" addPadding="pt-5 pb-5">
                                <template #default>
                                    <h2 class="text-primary">
                                        <i class="bi bi-folder-check" ></i>
                                        Frequently Asked Questions
                                    </h2>
                                    <p class="card-text mb-2">
                                     Let's support you quickly answer questions that we've been asked before.
                                    </p>
                                    <form class="kb-search-input">
                                        <div class="input-group input-group-merge">

                                            <input type="text" class="form-control" id="searchbar" placeholder=" Ask a question..." />
                                            <span class="input-group-text"><i class="bi bi-search" ></i> </span>
                                        </div>
                                    </form>
                                </template>
                            </base-card-plain>
                        </div>
                    </div>
                </section>
                <!--/ Knowledge base Jumbotron -->

                <!-- Knowledge base -->
                <section id="knowledge-base-content">
                    <div class="row kb-search-content-info match-height">
                        <!-- recruitment-talent-pool-list-card -->
                        <support-faqs-list>
                        </support-faqs-list>

                        <support-contact-platforms>
                        </support-contact-platforms>

                    </div>
                </section>
                <!-- Knowledge base ends -->

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { useStore } from 'vuex';


import SupportFaqsList from '@/components/support/SupportFaqsList.vue';
import SupportContactPlatforms from '@/components/support/SupportContactPlatforms.vue';

export default{
    components:{
        SupportFaqsList,
        SupportContactPlatforms
    },
    setup(){
        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);
    }
}


</script>

<style scoped>
.bs-icon-small{
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
}

.bs-icon-tiny{
    height: 0.5rem;
    width: 0.5rem;
    font-size: 0.5rem;
    margin-right: 1.1rem;
    flex-shrink: 0;}


</style>
